import config from './../config/config.js'

const rutaApi = config.rutaApisCliente + '/puntos'



//////////////////////////////////////////////////////////////
// Lista Todos los Puntos (data). 
// Devuelve tambien los PuntosMercados (data2).
// Devueve también la Tabla de Familias+Subfamilias (data3).
//////////////////////////////////////////////////////////////
const listaPuntos = () => {
  return fetch(rutaApi + '/listaPuntos', {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


//////////////////////////////////////////////////////////
// Lista Puntos de Proveedor/Coordinador, aunque no haga
// falta indicar un proveedor ni si es coordinador o no
//////////////////////////////////////////////////////////
const listaPuntosProvCoor = (params) => {
  return fetch(rutaApi + '/listaPuntosProvCoor/' + params.proveedor + '/' + params.esProductor, {
    method: 'GET',
  }).then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}


export {
  listaPuntos,
  listaPuntosProvCoor,
}