import React, { Fragment, useState, useEffect } from "react";
//import { Redirect } from "react-router-dom"
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden';

import ThemeGeroa from './../../components/ThemeGeroa.js'
//import { useStore, createStore} from 'react-hookstore';
import { useStore } from 'react-hookstore';

import BotonValidacion from './../../components/botones/BotonValidacion'

import auth from './../../apis/auth-helper.js'
import { listByUserCli } from './../../apis/api-pedido.js'
import { list } from './../../apis/api-producto.js'
import config from './../../config/config.js'

import { NumeroEsp, FechaLargaEsp, BuscaIndice, OrdenaArray, JsonAArray } from './../../config/funcionesPropias.js'
import { PrecioCliente, OrdenComplejo, TextoUnidad } from './../../config/funcionesParticulares.js'
import SelectorCantidad from './../../components/capturas/SelectorCantidad.js'
import Botonera from './../../core/Botonera.js'
import AmpliaImagen from './../../components/imagenes/AmpliaImagen.js'




const defaultImgProductor = require('./../../assets/images/defectos/productor_0000.png')

const useStyles = makeStyles(theme => ({
    // color mas claro #caae6b
    // color Cabecera #BD9A46
    // color mas oscuro #846b31
    registro: {
        marginTop: '20px',
        minHeight: '65vh',
        //border: "1px solid black",
    },
    // En Novedades y Ya Conocidos
    bordeGeneral: {
        border: '1px solid #CFD8DC',
        borderRadius: '5px'
    },
    cabecera: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
        //border: "2px solid gray",
    },
    cabeceraTitulo: {
        width: '100%',
    },
    cabeceraPedido: {
        display: "flex",
        //flexFlow: "row",
        justifyContent: 'flex-start',
        [theme.breakpoints.up("xs")]: { flexFlow: "row" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column" },
        //border: "1px solid magenta",
    },
    cabeceraPedidoLeft: {
        //width: '80%',
        [theme.breakpoints.up("xs")]: { width: '70%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        //border: "2px solid blue",
    },
    cabeceraPedidoRight: {
        marginTop: '10px',
        marginBottom: '10px',
        //width: '20%',
        [theme.breakpoints.up("xs")]: { width: '30%' },
        [theme.breakpoints.down("xs")]: { width: '100%' },
        // ¡¡Importante!! Esta linea despues de las anteriores. //
        textAlign: 'right',
        //border: "2px solid gray",
    },
    filaProductos: {
        //border: "1px solid red",
    },
    panelProducto: {
        marginBottom: '0px',
        // Para saltar la limitación de 20 pixeles por izquierda
        marginLeft: '-20px',
        // Para saltar la limitación de 20 pixeles por derecha
        marginRight: '-20px',
        boxShadow: 'none',
        //border: "1px solid blue",
    },
    cabeceraProducto: {
        display: "flex",
        //flexFlow: "row",
        //justifyContent: "space-between",
        [theme.breakpoints.up("xs")]: { flexFlow: "row", justifyContent: "flex-start", width: "90%" },
        [theme.breakpoints.down("xs")]: { flexFlow: "column", justifyContent: "space-between", width: "100%" },
        //border: "1px solid green",
    },
    /* // Estába incluída dentro de la clase anterior
    cabeceraProductoDetalle: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        width: '100%',
        //border: "1px solid yellow",
    },
    */
    fotoProducto: {
        marginLeft: '0px',
        maxHeight: 80,
        //border: "1px solid pink",
    },
    cabeceraProductoDatos: {
        marginLeft: '10px',
        //border: "1px solid red",
    },
    cabeceraProductor: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-start",
        width: '10%'
    },
    fotoProductor: {
        marginLeft: '10px'
    },
    /* // por ahora no hace falta
    cabeceraProductorDatos: {
        marginLeft: '20px',
        width: '100%',
        //border: "1px solid blue",
    },
    */
    masDatos: {
        marginTop: '-10px'
    },
    importeProducto: {
        color: '#1565c0',
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 20,
        [theme.breakpoints.up("xs")]: { marginLeft: 20, display: "inline" },
        [theme.breakpoints.down("xs")]: { marginLeft: 0, display: "block" },
    },
    totalYaConocidos: {
        color: '#616161',
        fontSize: 12,
        fontWeight: 500,
        [theme.breakpoints.up("xs")]: { display: "inline" },
        [theme.breakpoints.down("xs")]: { display: "block" },
    },
}));

// Inicializa variables para renderizar
//let familiasMercado = []
//let novedades = []
//let yaConocidos = []
//let ultimoPedido = ""
//let idiomaIni = ""
//let Eladio = 0
const MisProductos = ({ t }) => {
    const classes = useStyles();

    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    // Si viene del carrito (opción "5"), poner la opcion "1"
    const opcionMisProductos = (valuesGlobales.opcionMisProductos === "5" ? "1" : valuesGlobales.opcionMisProductos)

    const [carro, setCarro] = useState(valuesGlobales.carrito)

    // Familias, Novedades, yaConocidos
    const [datos, setDatos] = useState({
        familias: [],
        novedades: [],
        yaConocidos: [],
        ultimoPedido: "",
        //idiomaIni: "",
        nombreMercado: ""
    })

    //const [panelMasDatosProductosPorFamiliaAbierto, setPanelMasDatosProductosPorFamiliaAbierto] = useState()
    //const [panelMasDatosNovedadesAbierto, setPanelMasDatosNovedadesAbierto] = useState()
    //const [panelMasDatosYaConocidosAbierto, setPanelMasDatosYaConocidosAbierto] = useState()
    //const [panelMasDatosPedidoAbierto, setPanelMasDatosPedidoAbierto] = useState()

    const [repinta, setRepinta] = useState(false)

    // ------------------------------------------------------------ //
    // Documentación: https://es.reactjs.org/docs/hooks-effect.html //
    // ------------------------------------------------------------ //
    useEffect(() => {
        // Cargar Datos. //
        leeDatos()

        // El array vacío indica que sólo se se llama a useEffect 1 vez.
        // https://es.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
        // Recargar cuando el usuario se identifica (cambia emailUsuario)
        // Así se cargarán los datos de Pedidos del Usuario y Mercado.
        
        // Para que ignore el error: React Hook useEffect has a missing dependency: leeDatos. //
        // eslint-disable-next-line
    }, [valuesGlobales.emailUsuario])

    async function leeDatos() {
        // Inicializar datos que se renderizan
        let familiasMercado = []
        //datos.familias = []
        let novedades = []
        //datos.novedades = []
        let yaConocidos = []
        //datos.yaConocidos = []
        let ultimoPedido = ""
        //datos.ultimoPedido = ""
        // Nombre del Mercado. //
        let mercado = valuesGlobales.mercados.find(mer => mer.name === valuesGlobales.intentoMercado)
        if (mercado) { datos.nombreMercado = mercado.description }

        // Inicializar variables:
        // Debe ser la tarifa del usuario (teniendo en cuenta si es Iva
        // incluido y en este caso si tuviera recargo de equivalencia)


        // Preparar Ordenes por Parte de la descripcion + existencias
        let descripcion = valuesGlobales.idioma === "Es" ? "description" : "description2"
        let ordenes = [
            { "select": descripcion, "order": "ascending" },
            { "select": "existencias", "order": "descending" }
        ]

        const jwt = auth.isAuthenticated()

        //const tarifaCliente = (element) => element.numero === valuesGlobales.intentoMercado && element.disponible
        const tarifaCliente = (tarifa) => tarifa.numero === valuesGlobales.intentoMercado && tarifa.disponible && tarifa.tarifa !== 0

        try {
            // Para ver el tiempo que tarda
            //console.time("dataPro")

            ///////////////////////////////
            // Leer todos los productos. //
            ///////////////////////////////
            const dataPro = await list()
            //console.log('productos del 0700 al 0799 = ', dataPro.filter( codigo => codigo>"0700" && codigo <"0799"))

            // Mercado Ordenado por Familia y Productos Novedades
            // Sólo se añaden los productos del mercado/tarifa y visibles.
            let productosPorFamilia = OrdenaArray(dataPro, "category")
            let familiaAnterior = ""
            let familiaActual = ""
            let contador = -1
            let prodCarro = []

            //let tp1tp1 = productosPorFamilia.filter(producto => productoVisible(producto.visible, producto.quantity, producto.tarifas))
            //console.log('productosPorFamilia ', productosPorFamilia.length)
            //console.log("dataPro: ", dataPro, productosPorFamilia)            

            productosPorFamilia
                // filtrar productos visibles, con existencias y con tarifa válida (mercado actual y distinta de cero). //
                .filter(producto => productoVisible(producto.visible, producto.quantity, producto.tarifas))
                .map((producto) => {
                    familiaActual = producto.category
                    if (familiaAnterior !== familiaActual) {
                        familiasMercado.push({
                            "nombre": familiaActual,
                            "productos": [],
                            "panelAbierto": false // panel familias cerrado al entrar
                        })
                        ++contador
                        familiaAnterior = familiaActual
                    }
                    let datosTarifa = producto.tarifas.filter(tarifaCliente)
                    //console.log('datosTarifa 1 = ', datosTarifa)

                    datosTarifa = datosTarifa === undefined || datosTarifa.length === 0 ? { "tarifa": 0, "notas": "" } : datosTarifa[0]
                    //console.log('datosTarifa 2 = ', datosTarifa)

                    // Convertir el Código de Unidad a su Texto. //
                    producto.unidad = TextoUnidad(producto.unidad)
                    producto.unidad2 = TextoUnidad(producto.unidad2)

                    // Añadir objeto producto al array de productos de la familia actual. //
                    familiasMercado[contador].productos.push({
                        "id": producto._id,
                        "codigo": producto.name,
                        "unidad": producto.unidad,
                        "unidad2": producto.unidad2,
                        "factor": producto.factor,
                        "description": producto.description,
                        "description2": producto.description2,
                        "category": producto.category,
                        "tiendaId": producto.shop._id,
                        "tiendaCodigo": producto.shop.name,
                        "tiendaNombre": producto.shop.description,
                        "tiendaPoblacion": producto.shop.owner.direc[0].poblacion,
                        "tiendaProvincia": producto.shop.owner.direc[0].provincia,
                        "tarifaActual": datosTarifa.tarifa,
                        "iva": producto.iva,
                        "existencias": producto.quantity,
                        "controlEx": producto.controlEx,
                        "ingredientes": producto.ingredientes,
                        "notas": datosTarifa.notas,
                        "cantidad": 0,
                        "panelAbierto": false // panel ingredientes y notas cerrado al entrar
                    })

                    // Añadir objeto producto al array de Novedades. //
                    if (datosTarifa.oferta) {
                        novedades.push({
                            "id": producto._id,
                            "codigo": producto.name,
                            "unidad": producto.unidad,
                            "unidad2": producto.unidad2,
                            "factor": producto.factor,
                            "description": producto.description,
                            "description2": producto.description2,
                            "tiendaId": producto.shop._id,
                            "tiendaCodigo": producto.shop.name,
                            "tiendaNombre": producto.shop.description,
                            "tiendaPoblacion": producto.shop.owner.direc[0].poblacion,
                            "tiendaProvincia": producto.shop.owner.direc[0].provincia,
                            "tarifaActual": datosTarifa.tarifa,
                            "iva": producto.iva,
                            "existencias": producto.quantity,
                            "controlEx": producto.controlEx,
                            "ingredientes": producto.ingredientes,
                            "indicaciones": producto.indicaciones,
                            "notas": datosTarifa.notas,
                            "cantidad": 0,
                            "panelAbierto": false
                        })
                    }

                    // Ir cogiendo los precios actuales
                    prodCarro.push({
                        "id": producto._id,
                        "tarifaActual": datosTarifa.tarifa,
                        "iva": producto.iva
                    })
                    // return del map
                    return null
                })

            //console.log('familiasMercado = ', familiasMercado)
            // Guardar en Objeto datos
            datos.familias = familiasMercado

            // Guardar en valuesGlobales. //
            // Guarda prodCarro SOLO para poder calcular el importe del carro
            setValuesGlobales({ ...valuesGlobales, productos: prodCarro })

            // Pon el estado inicial en el que se van a abrir o cerrar los paneles
            // setState (array)
            //let tp = []
            //for (let i=0; i < familiasMercado.length; i++){
            //    tp.push(familiasMercado[i].productos.length <= 2)
            //}
            // setStore (objeto)
            //let tp = {}
            for (let i = 0; i < familiasMercado.length; i++) {
                /*
                // Si hay 1 o 2 productos: Abierto. //
                tp[i] = (familiasMercado[i].productos.length <= 2)
                if (!tp[i]) {
                    // Si algún producto de esta familia está en el carrito: Abierto. //
                    let productos = familiasMercado[i].productos
                    for (let j=0; j<productos.length; j++){
                        if (carro[productos[j].id] !== undefined) {
                            tp[i] = true
                            break
                        }
                    }
                }
                */
                // Modificación 17-01-2020: Todos Cerrados. //
                //tp[i] = false
                // Ordenar Productos de cada Familia por Parte de la descripcion + existencias
                OrdenComplejo(familiasMercado[i].productos, ordenes)
            }
            // Ordenar Novedades por Parte de la descripcion + existencias
            OrdenComplejo(novedades, ordenes)
            // Guardar en Objeto datos
            datos.novedades = novedades

            // tiempo que tarda procesar dataPro
            //console.timeEnd("dataPro")

            //////////////////////
            // Forzar Repintado //
            //////////////////////
            // Repintar antes de leer Pedidos
            setRepinta(!repinta)

        } catch (error) {
            console.error(error)
        }

        //////////////////////////////////////////
        // Solo si el usuario está identificado //
        //////////////////////////////////////////
        if (valuesGlobales.emailUsuario !== '') {
            try {
                // Para ver el tiempo que tarda
                //console.time("dataPed")

                const filtroMercado = (element) => element.mercado === valuesGlobales.intentoMercado

                /////////////////////////////////////////
                // Leer todos los Pedidos del Cliente. //
                /////////////////////////////////////////
                //console.log('antes de buscar los pedidos: jwt.user = ', jwt.user)
                const dataPed = await listByUserCli({ userId: jwt.user.codigo }, { t: jwt.token })
                if (dataPed.error) {
                    //console.log(dataPed)
                    // Salir y Visualizar el objeto err. //
                    throw dataPed.err
                }
                //console.log('despues de buscar los pedidos: dataPed = ', dataPed)

                // Barre todos los Pedidos del Cliente (Usuario)
                dataPed.filter(filtroMercado)
                    .map((order, i) => {
                        // Barre todas la lineas de productos de cada pedido del cliente
                        order.products.map((linea, j) => {
                            //
                            let datosTarifa = linea.product.tarifas.filter(tarifaCliente)
                            datosTarifa = datosTarifa === undefined || datosTarifa.length === 0 ? { "tarifa": 0, "notas": "" } : datosTarifa[0]

                            // Convertir el Código de Unidad a su Texto. //
                            linea.product.unidad = TextoUnidad(linea.product.unidad)

                            /////////////////////////////
                            // Guarda el último Pedido //
                            /////////////////////////////
                            let estado = ''
                            if (i === 0) {
                                if (j === 0) {
                                    switch (order.estado) {
                                        case 'SinPreparar':
                                            estado = 'Pendiente'
                                            break
                                        case 'AsignadoAReparto':
                                            estado = 'Pendiente'
                                            break
                                        case 'Entregado':
                                            estado = 'Entregado'
                                            break
                                        case 'ConGastos':
                                            estado = 'Entregado'
                                            break
                                        case 'Facturado':
                                            estado = 'Facturado'
                                            break
                                        case 'Cancelado':
                                            estado = 'Cancelado'
                                            break
                                        default:
                                            estado = order.estado
                                            break
                                    }

                                    // Cogemos la cabecera del pedido
                                    ultimoPedido = {
                                        id: order._id,
                                        fechaPedido: order.created,
                                        estadoPedido: estado,
                                        importePedido: 0,
                                        lineasPedido: []
                                    }
                                }
                                // Linea del Pedido
                                ultimoPedido.lineasPedido.push({
                                    "id": linea.product.name,
                                    "codigo": linea.product.name,
                                    "canPedida": linea.quantity,
                                    "unidad": linea.product.unidad,
                                    "unidad2": linea.product.unidad2,
                                    "factor": linea.product.factor,
                                    "controlEx": linea.product.controlEx,
                                    "precioCompra": linea.price,
                                    "description": linea.product.description,
                                    "description2": linea.product.description2,
                                    "tiendaId": linea.shop._id,
                                    "tiendaCodigo": linea.shop.name,
                                    "tiendaNombre": linea.shop.description,
                                    "tiendaPoblacion": linea.shop.owner.direc[0].poblacion,
                                    "tiendaProvincia": linea.shop.owner.direc[0].provincia,
                                    "tarifaActual": datosTarifa.tarifa,
                                    "iva": linea.product.iva,
                                    "existencias": linea.product.quantity,
                                    "ingredientes": linea.product.ingredientes,
                                    "notas": datosTarifa.notas,
                                    //"fechaCompra": FechaLargaEsp(new Date(order.created), valuesGlobales.idioma),
                                    "cantidad": 0,
                                    "visible": productoVisible(linea.product.visible, linea.product.quantity, linea.product.tarifas),
                                    "panelAbierto": false
                                })

                                // acumular el importe del ultimo pedido
                                ultimoPedido.importePedido += (linea.quantity * PrecioCliente(linea.price, linea.product.iva, valuesGlobales.ivaUsuario))
                            }

                            /* ---------------------------------------------------------------------------------------------------
                            -- Guarda los productos comprados (ya conocidos)                                                    --
                            -- sólo si el producto está activo (visible) y además el producto está disponible para el mercado   --
                            ----------------------------------------------------------------------------------------------------*/
                            if (productoVisible(linea.product.visible, linea.product.quantity, linea.product.tarifas)) {
                                // Si no está ya en el array (Guarda sólo una vez) //
                                // Se Añade si no se ha añadido ya en otra compra. //
                                //////////////////////////////////////////////////////////////////////////////////////////////if(BuscaIndice(yaConocidos, "id", linea.product._id) === -1){
                                if (BuscaIndice(yaConocidos, "codigo", linea.product.codigo) === -1) {
                                    yaConocidos.push({
                                        "id": linea.product.name,
                                        "codigo": linea.product.name,
                                        "canPedida": linea.quantity,
                                        "unidad": linea.product.unidad,
                                        "unidad2": linea.product.unidad2,
                                        "factor": linea.product.factor,
                                        "precioCompra": linea.price,
                                        "description": linea.product.description,
                                        "description2": linea.product.description2,
                                        "tiendaId": linea.shop._id,
                                        "tiendaCodigo": linea.shop.name,
                                        "tiendaNombre": linea.shop.description,
                                        "tiendaPoblacion": linea.shop.owner.direc[0].poblacion,
                                        "tiendaProvincia": linea.shop.owner.direc[0].provincia,
                                        "tarifaActual": datosTarifa.tarifa,
                                        "iva": linea.product.iva,
                                        "existencias": linea.product.quantity,
                                        "controlEx": linea.product.controlEx,
                                        "ingredientes": linea.product.ingredientes,
                                        "notas": datosTarifa.notas,
                                        "fechaCompra": order.created,
                                        "cantidad": 0,
                                        "panelAbierto": false
                                    })
                                }
                            }
                            // return del map
                            return null
                        })
                        // return del map
                        return null
                    })
                // Ordenar YaConocidos por parte descripcion y existencias
                OrdenComplejo(yaConocidos, ordenes)
                // Guardar en Objeto datos
                datos.yaConocidos = yaConocidos
                datos.ultimoPedido = ultimoPedido

                // tiempo que tarda procesar dataPed
                //console.timeEnd("dataPed")

                //console.log('ultimoPed = ', ultimoPedido)
                //console.log('yaConocidos = ', yaConocidos)

            } catch (error) {
                console.error(error)
            }
            //////////////////////
            // Forzar Repintado //
            //////////////////////
            //{ console.log('fp = ', datos) }
            setDatos({ ...datos, ultimoPedido: ultimoPedido })
        }
    }

    // El producto se verá solo si es visible y
    // producto está disponible en este mercado y
    // existe la tarifa del cliente.
    function productoVisible(visible, existencias, tarifas) {
        const tarifaCliente = (tarifa) => tarifa.numero === valuesGlobales.intentoMercado && tarifa.disponible && tarifa.tarifa !== 0
        return (visible && tarifas.some(tarifaCliente))
    }



    /////////////////////////////////
    /////////////////////////////////
    // MERCADO ORDENADO POR FAMILIAS
    /////////////////////////////////
    /////////////////////////////////

    // Hay que guardar inicialmente el estado en el que está (abierto Cerrado)
    const cambiaPanelFamilias = (index) => event => {
        //setPanelFamilias({...panelFamilias, [index]: !panelFamilias[index]})
        datos.familias[index].panelAbierto = !datos.familias[index].panelAbierto
        // Forzar repintado
        setRepinta(!repinta)
    }


    const cambiaPanelProductos = (index, j) => event => {
        /*
        let poner = false
        if(panelMasDatosProductosPorFamiliaAbierto !== undefined){
            if(panelMasDatosProductosPorFamiliaAbierto[index] !== undefined){
                poner = panelMasDatosProductosPorFamiliaAbierto[index]
            }
        }
        if(poner){
            poner = false
        }
        else {
            //if((familiasMercado[j].productos[index].ingredientes) || (familiasMercado[j].productos[index].notas !== "")){
            if((datos.familias[j].productos[index].ingredientes) || (datos.familias[j].productos[index].notas !== "")){
                poner = true
            }
        }
        setPanelMasDatosProductosPorFamiliaAbierto({...panelMasDatosProductosPorFamiliaAbierto, [index]: poner})
        */
        // Si el producto tiene ingredientes o notas
        if (datos.familias[j].productos[index].ingredientes || datos.familias[j].productos[index].notas !== "") {
            // Abrir o cerrar el panel
            datos.familias[j].productos[index].panelAbierto = !datos.familias[j].productos[index].panelAbierto
            // Forzar repintado
            setRepinta(!repinta)
        }
    }


    const handleChangeCantidadProductosPorFamilia = (name, i, j) => event => {

        datos.familias[j].productos[i].cantidad = event
        let carrito = { ...carro, [name]: event }
        setCarro({ ...carro, [name]: event })
        // Guarda en Local Storage el mercado Actual
        let mercadoActual = valuesGlobales.intentoMercado
        localStorage.setItem('mercadoActual', mercadoActual)
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(carrito))

        //console.log('datos.familias = ', datos.familias)
        //console.log('carrito guardado = ', name, " --- ", event, " ---- ", JSON.stringify(carrito))

        // Guarda en el Carro
        setValuesGlobales({ ...valuesGlobales, carrito: carrito, mercadoActual: mercadoActual, numeroCarrito: JsonAArray(carrito).length })
    }

    const listaProductosMercado = (
        <Fragment>
            <Grid container className={classes.cabecera}>
                <Grid item className={classes.cabeceraTitulo}>
                    <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 10 }} display="inline">
                        {/*t("MisProductos.FamiliasDeProductos")*/
                            datos.nombreMercado
                        }
                    </Typography>
                </Grid>
            </Grid>
            {datos.familias.map((familia, j) => (
                <Accordion
                    expanded={familia.panelAbierto}
                    key={'f' + j}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={cambiaPanelFamilias(j)}
                    >
                        <Typography style={{ color: "#026747", fontWeight: 500 }} display="inline">
                            {familia.nombre}
                        </Typography>
                    </AccordionSummary>
                    <Divider />
                    {familia.productos.map((linea, i) => (
                        linea.tarifaActual !== 0 &&
                        <Grid item className={classes.filaProductos}
                            key={'fp' + linea.codigo}>
                            <Accordion
                                className={classes.panelProducto}
                                expanded={linea.panelAbierto}>
                                <AccordionSummary
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    onClick={cambiaPanelProductos(i, j)}
                                >
                                    <Grid item className={classes.cabeceraProducto}>
                                        {/*<Grid item className={classes.cabeceraProductoDetalle}>*/}
                                        <Grid item className={classes.fotoProducto}>
                                            <AmpliaImagen
                                                fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '.jpg'}
                                                tamWidth={80}
                                                tamHeight={80}
                                                defaultImg={config.misImagenes + '/productos/producto_0000.jpg'}
                                            />
                                        </Grid>
                                        <Grid item className={classes.cabeceraProductoDatos}>
                                            <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                                {valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                            </Typography>
                                            <br />
                                            {Number(linea.controlEx) === 0 || Number(linea.existencias) > 0 ?
                                                <Fragment />
                                                :
                                                <Typography style={{ color: 'red', fontSize: 12, fontWeight: 500 }} display="inline">
                                                    {t("MisProductos.Agotado")}
                                                </Typography>
                                            }
                                            <br />
                                            <Typography style={{ color: '#1565c0', fontSize: 15, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.CantidadAPedir")}:
                                            </Typography>
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                                control={<SelectorCantidad
                                                    disabled={Number(linea.controlEx) > 0 && Number(linea.existencias) <= 0}
                                                    maximo={linea.existencias}
                                                    valor={cantidadCarrito(linea.id)}
                                                    accion={handleChangeCantidadProductosPorFamilia(linea.id, i, j)}
                                                />}
                                            />
                                            <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }} display="inline">
                                                {linea.unidad + " x " + NumeroEsp(PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario), -1) + " € "}
                                            </Typography>

                                            <Typography className={classes.importeProducto}>
                                                {t("MisProductos.ImporteProducto") + ": " + NumeroEsp(cantidadCarrito(linea.id) * PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario)) + " €"}
                                            </Typography>

                                            <br />
                                            {linea.factor !== "" && linea.factor !== 0 && linea.factor !== 1 && linea.tarifaActual > 0 &&
                                                < Fragment >
                                                    <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }}>
                                                        {valuesGlobales.idioma === "Es" ? t("MisProductos.ElProductoLeSaleA") : ""}
                                                        {PrecioCliente(linea.tarifaActual / linea.factor, linea.iva, valuesGlobales.ivaUsuario) + " € / " + linea.unidad2}
                                                        {valuesGlobales.idioma !== "Es" ? t("MisProductos.ElProductoLeSaleA") : ""}
                                                    </Typography>
                                                </Fragment>
                                            }

                                            <br />
                                            {linea.ingredientes ?
                                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500 }} display="inline">
                                                    {t("MisProductos.Ingredientes")}
                                                </Typography>
                                                :
                                                <Fragment />
                                            }
                                            {linea.notas !== "" ?
                                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500, marginLeft: 20 }} display="inline">
                                                    {t("MisProductos.Notas")}
                                                </Typography>
                                                :
                                                <Fragment />
                                            }
                                        </Grid>
                                        {/*</Grid>*/}
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid item className={classes.cabeceraProductor}>
                                            <Grid item className={classes.fotoProductor}>
                                                <img alt={linea.description} width="80px" height="80px"
                                                    src={config.misImagenes + '/shops/' + linea.tiendaCodigo + '.jpg'}
                                                    onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                                />
                                            </Grid>
                                            {/*
                                            <Grid item className={classes.cabeceraProductorDatos}>
                                                <Typography variant="body3" style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                                    {linea.tiendaPoblacion}
                                                </Typography>
                                                <br />
                                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                                    {linea.tiendaProvincia}
                                                </Typography>
                                                <br />
                                                <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                                    {linea.tiendaNombre}
                                                </Typography>
                                            </Grid>
                                            */}
                                        </Grid>
                                    </Hidden>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item className={classes.masDatos}>
                                        {linea.ingredientes ?
                                            <Grid item className={classes.masDatos}>
                                                <br />
                                                <Grid className={classes.masDatos1}>
                                                    <Grid item className={classes.masDatosIzquierda2}>
                                                        <AmpliaImagen
                                                            fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '_i.jpg'}
                                                            tamWidth={80}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Fragment />
                                        }
                                        {linea.notas !== "" ?
                                            <Fragment>
                                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 13, fontWeight: 500, marginRight: 10 }} display="inline" >
                                                    {t("MisProductos.Notas")}:
                                                </Typography>
                                                <Typography style={{ color: '#00233D', fontSize: 11, fontWeight: 400, marginRight: 10 }} display="inline" >
                                                    {linea.notas}
                                                </Typography>
                                            </Fragment>
                                            :
                                            <Fragment></Fragment>
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            {i < familia.productos.length - 1 ? <Divider /> : <Fragment />}
                        </Grid>

                    ))}
                </Accordion>
            ))}
        </Fragment>
    )


    ///////////////////////////////
    ///////////////////////////////
    //         NOVEDADES         //
    ///////////////////////////////
    ///////////////////////////////
    const cambiaPanelNovedades = index => event => {
        /*
        let poner = false
        if(panelMasDatosNovedadesAbierto !== undefined){
            if(panelMasDatosNovedadesAbierto[index] !== undefined){
                poner = panelMasDatosNovedadesAbierto[index]
            }
        }
        if(poner){
            poner = false
        }
        else {
            if((novedades[index].ingredientes) || (novedades[index].notas !== "")){
                poner = true
            }
        }
        setPanelMasDatosNovedadesAbierto({...panelMasDatosNovedadesAbierto, [index]: poner})
        */
        // Si el producto tiene ingredientes o notas
        if (datos.novedades[index].ingredientes || datos.novedades[index].notas !== "") {
            // Abrir o cerrar el panel
            datos.novedades[index].panelAbierto = !datos.novedades[index].panelAbierto
            // Forzar repintado
            setRepinta(!repinta)
        }
    }

    const handleChangeCantidadNovedades = (name, i) => event => {
        datos.novedades[i].cantidad = event
        let carrito = { ...carro, [name]: event }
        setCarro({ ...carro, [name]: event })
        // Guarda en Local Storage el mercado Actual
        let mercadoActual = valuesGlobales.intentoMercado
        localStorage.setItem('mercadoActual', mercadoActual)
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(carrito))
        // Guarda en el Carro
        setValuesGlobales({ ...valuesGlobales, carrito: carrito, mercadoActual: mercadoActual, numeroCarrito: JsonAArray(carrito).length })
    }


    function cantidadCarrito(productoId) {
        let cantidad = valuesGlobales.carrito[productoId]
        if (typeof cantidad !== "undefined") {
            return cantidad
        }
        return ''
    }


    const listaNovedades = (
        <Fragment>
            <Grid container className={classes.cabecera}>
                <Grid item className={classes.cabeceraTitulo}>
                    <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 10 }} display="inline">
                        {t("MisProductos.Novedades")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.bordeGeneral}>
                {datos.novedades.map((linea, i) => (
                    <Grid item className={classes.filaProductos}
                        key={'n' + i}>
                        <Accordion
                            className={classes.panelProducto}
                            expanded={linea.panelAbierto}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                onClick={cambiaPanelNovedades(i)}
                            >
                                <Grid item className={classes.cabeceraProducto}>
                                    {/*<Grid item className={classes.cabeceraProductoDetalle}>*/}
                                    <Grid item className={classes.fotoProducto}>
                                        <AmpliaImagen
                                            fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '.jpg'}
                                            tamWidth={80}
                                            tamHeight={80}
                                            defaultImg={config.misImagenes + '/productos/producto_0000.jpg'}
                                        />
                                    </Grid>
                                    <Grid item className={classes.cabeceraProductoDatos}>
                                        <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                        </Typography>
                                        <br />
                                        {Number(linea.controlEx) === 0 || Number(linea.existencias) > 0 ?
                                            <Fragment />
                                            :
                                            <Typography style={{ color: 'red', fontSize: 12, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Agotado")}
                                            </Typography>
                                        }
                                        <br />
                                        <Typography style={{ color: '#1565c0', fontSize: 15, fontWeight: 500 }} display="inline">
                                            {t("MisProductos.CantidadAPedir")}:
                                        </Typography>
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            disabled={Number(linea.existencias) <= 0}
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<SelectorCantidad maximo={linea.existencias} valor={cantidadCarrito(linea.id)} accion={handleChangeCantidadNovedades(linea.id, i)} />}
                                        />
                                        <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }} display="inline">
                                            {linea.unidad + " x " + NumeroEsp(PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario) - 1) + " € "}
                                        </Typography>
                                        <Typography className={classes.importeProducto}>
                                            {t("MisProductos.ImporteProducto") + ": " + NumeroEsp(cantidadCarrito(linea.id) * PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario)) + " €"}
                                        </Typography>
                                        <br />
                                        {linea.ingredientes ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Ingredientes")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }
                                        {linea.notas !== "" ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500, marginLeft: 20 }} display="inline">
                                                {t("MisProductos.Notas")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }
                                    </Grid>
                                    {/*</Grid>*/}
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item className={classes.cabeceraProductor}>
                                        <Grid item className={classes.fotoProductor}>
                                            <img alt={linea.description} width="80px" height="80px"
                                                src={config.misImagenes + '/shops/' + linea.tiendaCodigo + '.jpg'}
                                                onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                            />
                                        </Grid>
                                        {/*
                                    <Grid item className={classes.cabeceraProductorDatos}>
                                        <Typography variant="body3" style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {linea.tiendaPoblacion}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaProvincia}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaNombre}
                                        </Typography>
                                    </Grid>
                                    */}
                                    </Grid>
                                </Hidden>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item className={classes.masDatos}>
                                    {linea.ingredientes ?
                                        <Grid item className={classes.masDatos}>
                                            <br />
                                            <Grid className={classes.masDatos1}>
                                                <Grid item className={classes.masDatosIzquierda2}>
                                                    <AmpliaImagen
                                                        fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '_i.jpg'}
                                                        tamWidth={80}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Fragment />
                                    }
                                    {linea.notas !== "" ?
                                        <Fragment>
                                            <Typography style={{ color: '#FF6D00', fontSize: 11, fontWeight: 500, marginRight: 10 }} display="inline" >
                                                {t("MisProductos.Notas")}:
                                            </Typography>
                                            <Typography style={{ color: '#00233D', fontSize: 11, fontWeight: 400, marginRight: 10 }} display="inline" >
                                                {linea.notas}
                                            </Typography>
                                        </Fragment>
                                        :
                                        <Fragment></Fragment>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {i < datos.novedades.length - 1 ? <Divider /> : <Fragment />}
                    </Grid>
                ))}
            </Grid >
        </Fragment>
    )



    ////////////////////////////
    ////////////////////////////
    // PRODUCTOS YA CONOCIDOS //
    ////////////////////////////
    ////////////////////////////
    const cambiaPanelYaConocidos = index => event => {
        /*
        let poner = false
        if(panelMasDatosYaConocidosAbierto !== undefined){
            if(panelMasDatosYaConocidosAbierto[index] !== undefined){
                poner = panelMasDatosYaConocidosAbierto[index]
            }
        }
        if(poner){
            poner = false
        }
        else {
            if((yaConocidos[index].ingredientes) || (yaConocidos[index].notas !== "")){
                poner = true
            }
        }
        setPanelMasDatosYaConocidosAbierto({...panelMasDatosYaConocidosAbierto, [index]: poner})
        */
        // Si el producto tiene ingredientes o notas
        if (datos.yaConocidos[index].ingredientes || datos.yaConocidos[index].notas !== "") {
            // Abrir o cerrar el panel
            datos.yaConocidos[index].panelAbierto = !datos.yaConocidos[index].panelAbierto
            // Forzar repintado
            setRepinta(!repinta)
        }
    }

    const handleChangeCantidadYaConocidos = (name, i) => event => {
        datos.yaConocidos[i].cantidad = event
        let carrito = { ...carro, [name]: event }
        setCarro({ ...carro, [name]: event })
        // Guarda en Local Storage el mercado Actual
        let mercadoActual = valuesGlobales.intentoMercado
        localStorage.setItem('mercadoActual', mercadoActual)
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(carrito))
        // Guarda en el Carro
        setValuesGlobales({ ...valuesGlobales, carrito: carrito, mercadoActual: mercadoActual, numeroCarrito: JsonAArray(carrito).length })
    }

    const listaYaConocidos = (
        <Fragment>
            <Grid container className={classes.cabecera}>
                <Grid item className={classes.cabeceraTitulo}>
                    <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 10 }} display="inline">
                        {t("MisProductos.ProductosYaConocidos")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.bordeGeneral}>
                {datos.yaConocidos.map((linea, i) => (
                    <Grid item className={classes.filaProductos}
                        key={'y' + i}>
                        <Accordion
                            className={classes.panelProducto}
                            expanded={linea.panelAbierto}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                onClick={cambiaPanelYaConocidos(i)}
                            >
                                <Grid item className={classes.cabeceraProducto}>
                                    {/*<Grid item className={classes.cabeceraProductoDetalle}>*/}
                                    <Grid item className={classes.fotoProducto}>
                                        <AmpliaImagen
                                            fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '.jpg'}
                                            tamWidth={80}
                                            tamHeight={80}
                                            defaultImg={config.misImagenes + '/productos/producto_0000.jpg'}
                                        />
                                    </Grid>
                                    <Grid item className={classes.cabeceraProductoDatos}>
                                        <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 4 }} display="inline">
                                            {t("MisProductos.EnFecha")}
                                        </Typography>
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 10 }} display="inline">
                                            {FechaLargaEsp(new Date(linea.fechaCompra), valuesGlobales.idioma)}
                                        </Typography>
                                        <Typography className={classes.totalYaConocidos}>
                                            ({linea.canPedida + " " + linea.unidad + " x " +
                                                NumeroEsp(PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario)) + " € = " +
                                                NumeroEsp(linea.canPedida * PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario)) + " €"})
                                        </Typography>
                                        <br />
                                        {Number(linea.controlEx) === 0 || Number(linea.existencias) > 0 ?
                                            <Fragment />
                                            :
                                            <Typography style={{ color: 'red', fontSize: 12, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Agotado")}
                                            </Typography>
                                        }
                                        <br />
                                        <Typography style={{ color: '#1565c0', fontSize: 15, fontWeight: 500 }} display="inline">
                                            {t("MisProductos.CantidadAPedir")}:
                                        </Typography>
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<SelectorCantidad
                                                disabled={Number(linea.existencias) <= 0}
                                                maximo={linea.existencias}
                                                valor={cantidadCarrito(linea.id)}
                                                accion={handleChangeCantidadYaConocidos(linea.id, i)} />}
                                        />
                                        <Typography style={{ color: '#1565c0', fontSize: 13, fontWeight: 500 }} display="inline">
                                            {linea.unidad + " x " + NumeroEsp(PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario), -1) + " € "}
                                        </Typography>
                                        <Typography className={classes.importeProducto}>
                                            {t("MisProductos.ImporteProducto") + ": " + NumeroEsp(cantidadCarrito(linea.id) * PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario)) + " €"}
                                        </Typography>
                                        <br />
                                        {linea.ingredientes ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Ingredientes")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }
                                        {linea.notas !== "" ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500, marginLeft: 20 }} display="inline">
                                                {t("MisProductos.Notas")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }

                                    </Grid>
                                    {/*</Grid>*/}
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item className={classes.cabeceraProductor}>
                                        <Grid item className={classes.fotoProductor}>
                                            <img alt={linea.description} width="80px" height="80px"
                                                src={config.misImagenes + '/shops/' + linea.tiendaCodigo + '.jpg'}
                                                onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                            />
                                        </Grid>
                                        {/*
                                    <Grid item className={classes.cabeceraProductorDatos}>
                                        <Typography variant="body3" style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {linea.tiendaPoblacion}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaProvincia}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                            {linea.tiendaNombre}
                                        </Typography>
                                    </Grid>
                                    */}
                                    </Grid>
                                </Hidden>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item className={classes.masDatos}>
                                    {linea.ingredientes ?
                                        <Grid item className={classes.masDatos}>
                                            <br />
                                            <Grid className={classes.masDatos1}>
                                                <Grid item className={classes.masDatosIzquierda2}>
                                                    <AmpliaImagen
                                                        fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '_i.jpg'}
                                                        tamWidth={80}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Fragment />
                                    }
                                    {linea.notas !== "" ?
                                        <Fragment>
                                            <Typography style={{ color: '#FF6D00', fontSize: 11, fontWeight: 500, marginRight: 10 }} display="inline" >
                                                {t("MisProductos.Notas")}:
                                            </Typography>
                                            <Typography style={{ color: '#00233D', fontSize: 11, fontWeight: 400, marginRight: 10 }} display="inline" >
                                                {linea.notas}
                                            </Typography>
                                        </Fragment>
                                        :
                                        <Fragment></Fragment>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {i < datos.yaConocidos.length - 1 ? <Divider /> : <Fragment />}
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    )


    /////////////////////////////////
    /////////////////////////////////
    //        ULTIMO PEDIDO        //
    /////////////////////////////////
    /////////////////////////////////

    /*----------------------------------------------------
    -- Repetimos un Pedido: lo llevamos al carrito.     --
    -- Solo los productos que no estén ya en el carrito --
    -- y estén actualmente disponibles (visibles)       --
    ----------------------------------------------------*/
    function repetirPedido(event) {
        let carrito = { ...carro }
        let lineaPedido
        let anadir
        for (let i = 0; i < datos.ultimoPedido.lineasPedido.length; i++) {
            lineaPedido = datos.ultimoPedido.lineasPedido[i]
            anadir = (Number(lineaPedido.controlEx) === 0) || (Number(lineaPedido.controlEx) > 0 && Number(lineaPedido.existencias) > 0)

            if (lineaPedido.visible && anadir) {
                carrito[datos.ultimoPedido.lineasPedido[i].id] = datos.ultimoPedido.lineasPedido[i].canPedida
            }
        }
        setCarro({ ...carro, ...carrito })
        // Guarda en Local Storage el mercado Actual
        let mercadoActual = valuesGlobales.intentoMercado
        localStorage.setItem('mercadoActual', mercadoActual)
        // Guarda en Local Storage
        localStorage.setItem('carrito', JSON.stringify(carrito))
        // Guarda en el Carro
        setValuesGlobales({ ...valuesGlobales, carrito: carrito, mercadoActual: mercadoActual, numeroCarrito: JsonAArray(carrito).length })
        return true
    }

    const cambiaPanelPedido = index => event => {
        /*
        let poner = false
        if(panelMasDatosPedidoAbierto !== undefined){
            if(panelMasDatosPedidoAbierto[index] !== undefined){
                poner = panelMasDatosPedidoAbierto[index]
            }
        }
        if(poner){
            poner = false
        }
        else {
            if((ultimoPedido.lineasPedido[index].ingredientes) || (ultimoPedido.lineasPedido[index].notas !== "")){
                poner = true
            }
        }
        setPanelMasDatosPedidoAbierto({...panelMasDatosPedidoAbierto, [index]: poner})
        */
        // Si el producto tiene ingredientes o notas
        if (datos.ultimoPedido.lineasPedido[index].ingredientes || datos.ultimoPedido.lineasPedido[index].notas !== "") {
            // Abrir o cerrar el panel
            datos.ultimoPedido.lineasPedido[index].panelAbierto = !datos.ultimoPedido.lineasPedido[index].panelAbierto
            // Forzar repintado
            setRepinta(!repinta)
        }
    }

    const listaUnPedido = (
        datos.ultimoPedido !== "" ?
            <Fragment>
                <Grid container className={classes.cabecera}>
                    <Grid item className={classes.cabeceraTitulo}>
                        <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 10 }} display="inline">
                            {t("MisProductos.UltimoPedido")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.cabeceraPedido}>
                    <Grid item className={classes.cabeceraPedidoLeft}>
                        <Typography style={{ color: '#846b31', fontWeight: 500, marginRight: 10 }} display="inline">
                            {t("MisProductos.Pedido")}:
                        </Typography>
                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 10 }} display="inline">
                            {datos.ultimoPedido.id}
                        </Typography>
                        <Typography style={{ color: '#846b31', fontWeight: 500 }} display="inline">
                            {t("MisProductos." + datos.ultimoPedido.estadoPedido)}
                        </Typography>
                        <br />
                        <Typography style={{ color: '#846b31', fontWeight: 500, marginRight: 10 }} display="inline" >
                            {t("MisProductos.FechaPedido")}:
                        </Typography>
                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                            {FechaLargaEsp(new Date(datos.ultimoPedido.fechaPedido), valuesGlobales.idioma)}
                        </Typography>
                        <br />
                        <Typography style={{ color: '#846b31', fontWeight: 500, marginRight: 10 }} display="inline">
                            {t("MisProductos.ImportePedido")}:
                        </Typography>
                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                            {NumeroEsp(datos.ultimoPedido.importePedido)} €
                        </Typography>
                    </Grid>
                    <Grid item className={classes.cabeceraPedidoRight}>
                        <BotonValidacion
                            texto={t("MisProductos.RepetirPedido")}
                            validacion={repetirPedido}
                            salto="/CarroCompra"
                        />
                    </Grid>
                </Grid>
                <Divider />
                {datos.ultimoPedido.lineasPedido.map((linea, i) => (
                    <Grid item className={classes.filaProductos}
                        key={'u' + i}>
                        <Accordion
                            className={classes.panelProducto}
                            expanded={linea.panelAbierto}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                onClick={cambiaPanelPedido(i)}
                            >
                                <Grid item className={classes.cabeceraProducto}>
                                    {/*<Grid item className={classes.cabeceraProductoDetalle}>*/}
                                    <Grid item className={classes.fotoProducto}>
                                        <AmpliaImagen
                                            fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '.jpg'}
                                            tamWidth={80}
                                            tamHeight={80}
                                            defaultImg={config.misImagenes + '/productos/producto_0000.jpg'}
                                        />
                                    </Grid>
                                    <Grid item className={classes.cabeceraProductoDatos}>
                                        <Typography style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                            {valuesGlobales.idioma === "Es" ? linea.description : linea.description2}
                                        </Typography>
                                        <br />
                                        <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500, marginRight: 20 }} display="inline">
                                            ({linea.canPedida} {linea.unidad} x {NumeroEsp(PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario), -1)} € = {NumeroEsp(linea.canPedida * PrecioCliente(linea.precioCompra, linea.iva, valuesGlobales.ivaUsuario))} €)
                                        </Typography>
                                        {Number(linea.controlEx) === 0 || Number(linea.existencias) > 0 ?
                                            <Fragment />
                                            :
                                            <Typography style={{ color: 'red', fontSize: 12, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Agotado")}
                                            </Typography>
                                        }
                                        <br />
                                        {linea.visible ?
                                            <Fragment>
                                                <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 600, marginLeft: 0 }} display="inline">
                                                    {t("MisProductos.PrecioActual")}:
                                                </Typography>
                                                <Typography style={{ color: '#1565c0', fontSize: 14, fontWeight: 600, marginLeft: 5 }} display="inline">
                                                    {NumeroEsp(PrecioCliente(linea.tarifaActual, linea.iva, valuesGlobales.ivaUsuario)) + " € / " + linea.unidad}
                                                </Typography>
                                            </Fragment>
                                            :
                                            <Typography style={{ color: '#c62828', fontSize: 14, fontWeight: 600, marginLeft: 0 }} display="inline">
                                                {t("MisProductos.NoDisponible")}
                                            </Typography>
                                        }
                                        <br />
                                        {linea.ingredientes ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500 }} display="inline">
                                                {t("MisProductos.Ingredientes")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }
                                        {linea.notas !== "" ?
                                            <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 11, fontWeight: 500, marginLeft: 20 }} display="inline">
                                                {t("MisProductos.Notas")}
                                            </Typography>
                                            :
                                            <Fragment />
                                        }
                                    </Grid>
                                    {/*</Grid>*/}
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item className={classes.cabeceraProductor}>
                                        <Grid item className={classes.fotoProductor}>
                                            <img alt={linea.description} width="80px" height="80px"
                                                src={config.misImagenes + '/shops/' + linea.tiendaCodigo + '.jpg'}
                                                onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                            />
                                        </Grid>
                                        {/*
                                        <Grid item className={classes.cabeceraProductorDatos}>
                                            <Typography variant="body3" style={{ color: '#1565c0', fontWeight: 500 }} display="inline">
                                                {linea.tiendaPoblacion}
                                            </Typography>
                                            <br />
                                            <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                                {linea.tiendaProvincia}
                                            </Typography>
                                            <br />
                                            <Typography style={{ color: '#616161', fontSize: 12, fontWeight: 500 }} display="inline">
                                                {linea.tiendaNombre}
                                            </Typography>
                                        </Grid>
                                        */}
                                    </Grid>
                                </Hidden>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item className={classes.masDatos}>
                                    {linea.ingredientes ?
                                        <Grid item className={classes.masDatos}>
                                            <br />
                                            <Grid className={classes.masDatos1}>
                                                <Grid item className={classes.masDatosIzquierda2}>
                                                    <AmpliaImagen
                                                        fotoPeq={config.misImagenes + '/productos/' + linea.codigo + '_i.jpg'}
                                                        tamWidth={80}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Fragment />
                                    }
                                    {linea.notas !== "" ?
                                        <Fragment>
                                            <Typography style={{ color: '#FF6D00', fontSize: 11, fontWeight: 500, marginRight: 10 }} display="inline" >
                                                {t("MisProductos.Notas")}:
                                            </Typography>
                                            <Typography style={{ color: '#00233D', fontSize: 11, fontWeight: 400, marginRight: 10 }} display="inline" >
                                                {linea.notas}
                                            </Typography>
                                        </Fragment>
                                        :
                                        <Fragment></Fragment>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {i < datos.ultimoPedido.lineasPedido.length - 1 ? <Divider /> : <Fragment />}
                    </Grid>
                ))}
            </Fragment>
            :
            <Fragment>
                <Grid container className={classes.cabecera}>
                    <Grid item className={classes.cabeceraTitulo}>
                        <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 10 }} display="inline">
                            {t("MisProductos.UltimoPedido")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Fragment>
    )

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                {<Botonera />}
                {opcionMisProductos === '1' ? listaProductosMercado : <Fragment />}
                {opcionMisProductos === '2' ? listaNovedades : <Fragment />}
                {opcionMisProductos === '3' ? listaYaConocidos : <Fragment />}
                {opcionMisProductos === '4' ? listaUnPedido : <Fragment />}
            </Container>
            <br />
        </MuiThemeProvider>
    );
}

export default withTranslation()(MisProductos)


/*
              <MagnifierContainer>
                    <div className="example-class">
                        <MagnifierPreview imageSrc="./image.jpg" />
                    </div>
                    <MagnifierZoom style={{ height: "400px" }} imageSrc="./image.jpg">
                </MagnifierContainer>

*/