import React, { useState } from "react";
import { createStore, useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"

//import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { signinCli } from './../../apis/api-auth.js'
import auth from './../../apis/auth-helper.js'
import { listaNotificacionesUsuario } from "../../apis/api-notificacion.js";
//import { listUltimoPedidoUsuarioEnMercado } from "../../apis/api-pedido"

import ThemeGeroa from '../../components/ThemeGeroa.js'
import Boton from '../../components/botones/Boton'
import BotonValidacion from '../../components/botones/BotonValidacion'

import config from './../../config/config'
import { BuscaIndice } from '../../config/funcionesPropias.js'


const useStyles = makeStyles(theme => ({
    registro: {
        marginTop: '0px',
        minHeight: '65vh'
    },
    mensajeHelper: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    mensajeError: {
        color: '#ff3878',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    textField: {
        marginBottom: 12,
    },
    conSubrayado: {
        textDecoration: 'underline blue',
        color: 'blue',
        cursor: 'pointer'
    },
    nounderline: {
        //textDecoration: 'none',
        color: 'blue',
        cursor: 'pointer',
    }
}));


createStore('Datos')

const Identificarse = ({ t }) => {
    const classes = useStyles();
    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

    const [salto, setSalto] = useState('')

    const [values, setValues] = useStore('Datos');
    const [errors, setErrors] = useState({})

    if (values.usuario === undefined) {
        values.usuario = ''
    }
    if (values.password === undefined) {
        values.password = ''
    }

    const handleChange = name => event => {
        // No permitir comillas ni dobles ni sencillas. //
        let texto = event.target.value.split("'").join('')
        texto = texto.split('"').join('')
        setValues({ ...values, [name]: texto });
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    // Localizamos el tipo de mercado al que se está intentado ir
    let mercadoAbierto = true
    if(valuesGlobales.intentoMercado !== ""){
         let tp = BuscaIndice(valuesGlobales.mercados, 'name', valuesGlobales.intentoMercado)
         if(tp !== -1){
             mercadoAbierto = valuesGlobales.mercados[tp].abierto
        }
    }





    // Valida únicamente la sintaxis de los 2 campos (email y password)
    const validate = (values) => {
        if (values.usuario.length === 0) {
            errors.usuario = t('El Usuario es obligatorio')
        }
        else {
            errors.usuario = '';
        }
        if (values.password.length === 0) {
            errors.password = t('El password es obligatorio')
        } else if (values.password.length < 4) {
            errors.password = t('Este password parece demasiado corto')
        }
        else {
            errors.password = ''
        }
        return errors
    }

    async function handleSubmit(event) {
        event.preventDefault()

        // Valida que se haya rellenado el formulario
        setErrors(validate(values))
        setValues({ ...values, usuario: values.usuario, password: values.password });
        if (errors.usuario === '' && errors.password === '') {
            try {
                const data = await signinCli({ u: values.usuario, p: values.password });
                //console.log('signin = ', data)
                if (data === undefined) {
                    throw t('El Usuario o el password no son correctos')
                } else if (data === 'Usuario o Password incorrectos') {
                    throw t('El Usuario o el password no son correctos')
                }
                else {
                    auth.authenticate(data, () => {
                        // Coge algún dato que se necesite de Usuarios
                        let iva = 0
                        if (data.user.ivaUsuario) {
                            iva = 1
                            if (data.user.recargoUsuario) {
                                iva = 2
                            }
                        }

                        setValuesGlobales({
                            ...valuesGlobales,
                            ivaUsuario: iva,
                            usuario: data.user.usuario,
                            nombreUsuario: data.user.name,
                            emailUsuario: data.user.email,
                            codigoUsuario: data.user.codigo,
                            nifUsuario: data.user.nif,
                            dirUsuario: data.user.direcciones,
                            mercadosUsuario: data.user.tarifas
                        })
                        //console.log('tras autenticar = ', valuesGlobales)
                        //console.log('data user = ', data.user)
                        // Coge las notificaciones Si las hay
                        //listaNotificacionesUsuario({usuarioEmail: data.user.email}, {t: jwt.token}).then((dataN) => {
                        if (config.notificaciones) {
                            listaNotificacionesUsuario({ usuarioEmail: data.user.email }, { t: '' }).then((dataN) => {
                                if (dataN.error) {
                                    console.log(dataN.error)
                                }
                                else {
                                    setValuesGlobales({
                                        ...valuesGlobales,
                                        notificaciones: dataN
                                    })
                                }
                            })
                        }
                    })
                    errors.password = ''
                }
            }
            catch (err) {
                errors.password = err
            }
            // Actualizar errores.
            setErrors(errors)
            // Necesario para que pinte en pantalla si hay error.
            setValues({ ...values, usuario: values.usuario, password: values.password });
        }
        return (errors.usuario === '' && errors.password === '') ? true : false
    }

    const salta = (p) => (event) => {
        if (p === "AltaNuevoUsuario") {
            // Saltar a la Pantalla de Nuevo Usuario
            setSalto('/Usuarios')
        } else {
            // Saltar a la Pantalla de Olvidé el Usuario
            setSalto('/Password')
        }
    }

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Container maxWidth="md" className={classes.registro}>
                <Typography style={{ fontSize: '32px', color: '#00233D', fontWeight: 600, marginTop: 10, marginBottom: 10 }}>
                    {t('Identificación')}
                </Typography>
                <Grid item xs={7}>
                    {(valuesGlobales.paginaOrigen !== "/" || valuesGlobales.intentoMercado !== '') &&
                        (mercadoAbierto ?
                            <>
                                <Typography align="justify" style={{ fontSize: '14px', color: ThemeGeroa.palette.primary.main, fontWeight: 400, marginTop: 10 }} display="inline">
                                    {t('TextoMerAbierto1')}
                                </Typography>
                                <Typography align="justify" style={{ fontSize: '14px', color: ThemeGeroa.palette.primary.main, fontWeight: 400, marginLeft: 3 }} display="inline">
                                    {t('TextoMerAbierto3')}
                                </Typography>
                                <br />
                            </>
                            :
                            <Typography align="justify" style={{ fontSize: '14px', color: ThemeGeroa.palette.primary.main, fontWeight: 400, marginTop: 10, marginBottom: 18 }}>
                                {t('TextoMerCerrado')}
                            </Typography>
                        )
                    }
                    <TextField
                        className={classes.textField}
                        id="usuario"
                        type="text"
                        required
                        label={t("Usuario")}
                        value={values.usuario}
                        fullWidth
                        onChange={handleChange('usuario')}
                    />
                    {(() => {
                        if (errors.usuario === undefined || errors.usuario === '') {
                            return <Typography className={classes.mensajeHelper}>
                                {t('Introduzca su Usuario')}
                            </Typography>
                        } else {
                            return <Typography className={classes.mensajeError}>
                                {errors.usuario}
                            </Typography>
                        }
                    })()}
                    <br />
                    <FormControl fullWidth>
                        <InputLabel required htmlFor={"password"} /* corregir warning de Label */>
                            {t("Password")}
                        </InputLabel>
                        <Input
                            className={classes.textField}
                            inputProps={{id:"password", name: "password"} /* evitar warning de Label */}
                            //id="password"
                            type={values.showPassword ? 'text' : "Password"}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {(() => {
                            if (errors.password === undefined || errors.password === '') {
                                return <Typography className={classes.mensajeHelper}>
                                    {t('Introduzca su Password')}
                                </Typography>
                            } else {
                                return <Typography className={classes.mensajeError}>
                                    {errors.password}
                                </Typography>
                            }
                        })()}
                    </FormControl>
                    <br />
                    <br />
                    <Grid container>
                        <Grid item onClick={salta("AltaNuevoUsuario")} className={classes.nounderline}>
                            <Typography style={{ fontSize: '13px', fontWeight: '400' }} display="inline">
                                {t("Identificarse.NoSoyUsuario")}
                            </Typography>
                        </Grid>
                        <Grid item onClick={salta("SaltaRecibirPassword")} className={classes.nounderline}>
                            <Typography style={{ fontSize: '13px', fontWeight: '400', marginLeft: '20px', marginRight: 20 }} display="inline">
                                {t("Identificarse.OlvideElPassword")}
                            </Typography>
                        </Grid>
                        <Boton
                            texto={t('Volver')}
                            salto={valuesGlobales.paginaOrigen}
                        />
                        <Typography style={{ fontSize: '13px', fontWeight: '400', marginRight: 10 }} display="inline" />
                        <BotonValidacion
                            texto={t('Aceptar')}
                            //salto={valuesGlobales.paginaOrigen}
                            salto={"/MisMercados"}
                            validacion={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Container>
            {(() => {
                if (salto !== '') {
                    return <Redirect to={salto} />
                }
            })()}
        </MuiThemeProvider >
    );
}

export default withTranslation()(Identificarse)