import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { Link } from "react-router-dom"
import Popper from "@material-ui/core/Popper"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuList from "@material-ui/core/MenuList"
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import BotonSinSalto from './BotonSinSalto.js'
import ThemeGeroa from '../ThemeGeroa.js'
import {useStore } from 'react-hookstore';

import auth from './../../apis/auth-helper.js'
import BotonValidacion from './BotonValidacion.js';

const useStyles = makeStyles(theme => ({
    botonHeaderLogin: {
        textTransform: "none",
        fontSize: "16px",
        [ThemeGeroa.breakpoints.down('md')]: { fontSize: '13px' },
        padding: "0px 10px 0px 10px !important",
        [ThemeGeroa.breakpoints.down('md')]: { padding: '0px 5px 0px 5px !important' },
        fontWeight: "400",
        color: "#FFF",
        backgroundColor: "transparent",
        maxHeight: '30px',
        minWidth: '90px',
        minHeight: '30px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "transparent",
            cursor: "hand",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
}));



export default function BotonHeaderLogin({ ...props }) {
    const classes = useStyles();

    // Parámetros recibidos: Eemplo de llamada:
    //        <BotonHeaderHeaderLogin
    //           opciones={miLista}                 // Obligatorio
    //        >
    //        </BotonHeaderHeaderLogin>
    //
    //              Donde    let miLista = []
    //                           miLista[0] = { "texto": "Identificarse", "habilitado": true}
    //                           miLista[1] = { "texto": "Desconectarse", "habilitado": true}

    const [anchorEl, setAnchorEl] = useState(null);
    const [, setIndex] = useState()
    const [open, setOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');

    function handleToggle(event) {
        setAnchorEl(event.currentTarget)
        setOpen(!open);
    };

    function handleClose(event) {
        if(open) {
            setOpen(!open);
        }
    }

    function handleMenuItemClick(i) {
        setIndex(i)
        setOpen(!open);
    }
  
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
   
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseDialogDesconectar = () => {
        setOpenDialog(false);
        // forzar cierre opciones.
        setOpen(!open)
 
        auth.signoutCli((()=>{}))
        let mA = valuesGlobales.mercadoActual
        setValuesGlobales({ ...valuesGlobales,  
                            nombreUsuario: '',
                            emailUsuario: '',
                            mercadosUsuario: [],
                            intentoMercado: mA,
                            paginaOrigen: '/', // Al desconectarse, debe volver al mapa
                            //paginaDestino: '', // me parece que no se usa, revisar CarroCompra
                            //tarifaUsuario: 0,  // solo se usa en CarroCompra comparando con "008"
                            ivaUsuario: 0,
                            dirUsuario:[],
                            mercados:[]    // Vaciar para que se vuelvan a cargar
                        })
        return true
    };

    // Lista de opciones
    let miListaOpciones = props.opciones.map((opcion, index) => (
        index !== 1 ?
            <MenuItem 
                className={classes.subMenu}
                component={Link} to={opcion.salto}
                key={index}
                disabled={!opcion.habilitado}
                onClick={event => handleMenuItemClick(index)}
            >
                {opcion.texto}
            </MenuItem>
        :
            <MenuItem
                className={classes.subMenu}
                key={index}
                disabled={!opcion.habilitado}
                onClick={event => handleClickOpenDialog(index)}
            >
                {opcion.texto}
            </MenuItem>
    ))

    return (
        <Fragment>
            <IconButton color="inherit"
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                transitioncomponent={Fade}
                >
                <AccountCircle />
            </IconButton>
            <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {miListaOpciones}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Dialog
               open={openDialog}
               onClose={handleCloseDialog}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.textoTitulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.textoCaja}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BotonSinSalto
                       texto={props.botonCancelarCaja}
                       accion={handleCloseDialog}
                    />
                    <BotonValidacion
                        texto={props.botonAceptarCaja}
                        validacion = {handleCloseDialogDesconectar}
                        salto ={"/"}
                    />
                </DialogActions>
            </Dialog>
        </Fragment>
    )
};