import React, { Fragment } from 'react';
//import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

//import Europa from "./../assets/images/logos/europa.png";
//import GobiernoVasco from "./../assets/images/logos/gobiernoVasco.png";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
//import ThemeGeroa from '../components/ThemeGeroa.js'


const useStyles = makeStyles(theme => ({
  Footer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    marginTop: '10px',
    marginBottom: '20px',
    backgroundColor: "#026747",
    color: '#FFF'
  },
  bloque: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
  },
  bloqueRight: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginLeft: '10px',
  },
  logo: {
    marginTop: '20px',
    padding: '10px'
  },
  link: {
    color: '#00F'
  }
}));


const StickyFooter = ({ t }) => {

  const classes = useStyles();
  return (
    <Fragment>
      <Grid container className={classes.Footer}>
        <Container maxWidth="md" className={classes.bloque}>
          <Grid item>
            <Typography align='center' style={{ fontSize: '14px', fontWeight: '500' }}>
              Tel 94 - 627 15 94
              <br />
              Email: sustatu@ekolurra.eus
            </Typography>
            <Typography align='center' style={{ fontSize: '13px', fontWeight: '400' }}>
              © 2020 sareko.net - {t("SubFooter.Derechos")}
            </Typography>
            <Link to='/Cookies'>
              <Typography style={{ fontSize: '13px', fontWeight: '400' }} display="inline" className={classes.link}>
                {t("SubFooter.Cookies")}
              </Typography>
            </Link>
            <Link to='/AvisoLegal'>
              <Typography style={{ fontSize: '13px', fontWeight: '400', marginLeft: 50, }} display="inline" className={classes.link}>
                {t("SubFooter.Aviso")}
              </Typography>
            </Link>
            <Link to='/Condiciones'>
              <Typography style={{ fontSize: '13px', fontWeight: '400', marginLeft: 50 }} display="inline" className={classes.link}>
                {t("SubFooter.Condiciones")}
              </Typography>
            </Link>
            <br />
            <br />
          </Grid>
        </Container>
      </Grid>
    </Fragment >
  );
}
export default withTranslation()(StickyFooter)