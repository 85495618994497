import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormLabel from '@material-ui/core/FormLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
//import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
//import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box"
//import Button from "@material-ui/core/Button"

// NativeSelect para movil. //
//import NativeSelect from '@material-ui/core/NativeSelect';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';
//import { motion } from 'framer-motion/dist/framer-motion'

import 'date-fns';

//import auth from './../../apis/auth-helper.js'
import config from './../../config/config.js'
import ThemeGeroa from './../../components/ThemeGeroa.js'

import Divider from "@material-ui/core/Divider"

import { listaPuntos } from "../../apis/api-punto.js";

//import Boton from '../../components/botones/Boton.js'
import BotonValidacion from '../../components/botones/BotonValidacion.js'

/*
// Ejemplos para capturar familias en un Dialogo. //
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));
*/

const useStyles = makeStyles(theme => ({
    /// color mas claro #caae6b
    ///color Cabecera #BD9A46
    ///color mas oscuro #846b31
    registro: {
        marginTop: '20px',
        minHeight: '65vh'
    },
    boton: {
        color: '#FFF',
        backgroundColor: '#1b5e20',
        textTransform: "none",
        marginRight: '10px',
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    },
    cabTitulo: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
        alignItems: 'center', // alinea el contenido del Grid
        //border: "1px solid magenta",
        width: '100%',
        height: '100%',
        marginTop: '-20px',
    },
    pieMapa: {
        display: "flex",
        flexFlow: "row",
        justifyContent: 'flex-start',
        alignItems: 'center', // alinea el contenido del Grid
        //border: "1px solid magenta",
        width: '100%',
        height: '100%',
        marginTop: '10px',
    },
    cabTituloLeft: {
        //border: "2px solid blue",
    },
    cabTituloRight: {
        textAlign: 'right', // Alinea el boton a la Dcha.
        //border: "2px solid gray",
    },
    cabFiltros: {
        justifyContent: 'space-between', // Alin. Hor.: 'space-between', 'space-around', 'flex-start', 'flex-end'
        alignItems: 'center', // Alin. contenido del Grid Vert.: flex-start, center, flex-end, 
        //border: "1px solid magenta",
    },
    cabFiltros1: {
        marginTop: '-10px',
    },
    estados: {
        textAlign: 'left', // Alinea el contenido del boton a la Izda.
    },
    menuItem: {
        fontSize: 13,
        color: '#757575'
    },
    textFiltro: {
        marginBottom: 10,
        //marginRight: 25,
        //minWidth: 345,
        maxWidth: 400
    },
    selectFiltro: {
        marginBottom: 10,
        //marginRight: 25,
        minWidth: 345,
        maxWidth: 400
    },
    mensajeHelper: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-6px',
        marginBottom: 10,
        minHeight: '1em',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },
    filtroTipoPunto: {
        //border: "1px solid red",
        marginBottom: 10,
        minWidth: 170,
        maxWidth: 170,
    },

    // COLORES POSIBLES A USAR EN EL MAPA
    // Azul
    azul17: {
        color: '#1565c0',
        fontWeight: 500,
        fontSize: 17,
        marginTop: -5
    },
    azul15: {
        color: '#1565c0',
        fontWeight: 500,
        fontSize: 15,
        marginTop: 0
    },
    // Verde
    verde15: {
        color: 'green',
        fontWeight: 500,
        fontSize: 15,
        marginTop: 0
    },
    verde10: {
        color: 'green',
        fontWeight: 500,
        fontSize: 10,
        marginTop: 0,
        marginLeft: 15
    },
    // Gris
    gris15: {
        color: '#616161',
        fontWeight: 500,
        fontSize: 15,
        marginTop: 0
    },
    gris11: {
        color: '#616161',
        fontWeight: 500,
        fontSize: 11,
        marginTop: 0
    },

}));


// -- Para Parametrizar el Select de Familias-SubFamilias. -- //
// Consultar en: https://v4.mui.com/components/selects/       //
const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 20 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const MisMapas = ({ t }) => {
    const classes = useStyles();
    //const jwt = auth.isAuthenticated()

    const [valuesGlobales, setValuesGlobales] = useStore('DatosGlobales');
    //const [mensaje, setMensaje] = useState("")
    const [estado, setEstado] = useState(
        {
            inicio: true,
            todosPuntos: [],
            puntosAVer: [],
            nombrePunto: '',
            tiposDePuntos: [],
            tiposSelec: [],
            importancia: -1,
            bajoPedido: -1,
            sareko: true,
            familias: [],
            famYSub: [],
            puntosMercados: [],
            posCentral: { lat: 43.0745200, lng: -2.4841500 },        // Mondragon
            numPuntoAbierto: -1
        }
    )
    //const posEneek = { lat: 43.21290376030901, lng: -2.726953625679016 }
    //const posMondragon = { lat: 43.0745200, lng: -2.4841500 }



    const textoTipoPunto = (tipo) => {
        let tp = estado.tiposDePuntos.find((t) => t.ind === tipo)
        return tp.tex
    }

    /*
        const tiposDeImportancia = [
            t("MisMapas.Importancia0"), //"Mas de 3 productores ó más de 3 mercados",
            t("MisMapas.Importancia1"), //"Menos de 3 productores y menos de 3 mercados",
        ]
    */


    useEffect(() => {
        // Vaciar los mercados del Punto y el Punto cada vez que entramos. //
        setValuesGlobales({ ...valuesGlobales, mercadosMapa: [], punto: '' })
        // Para que ignore el error: React Hook useEffect has a missing dependencies: //
        // setValuesGlobales and valuesGlobales //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        // Para limpieza. //
        let isMounted = true
        let tp = [
            { ind: 0, tex: t("MisMapas.Tipo0") }, //"Venta en el baserri o lugar de producción",
            { ind: 1, tex: t("MisMapas.Tipo1") }, //"Tienda de productores",
            { ind: 2, tex: t("MisMapas.Tipo2") }, //"Punto de entrega de cestas",
            { ind: 3, tex: t("MisMapas.Tipo3") }, //"Mercado semanal",
            { ind: 4, tex: t("MisMapas.Tipo4") }, //"Tienda especializada",
            { ind: 5, tex: t("MisMapas.Tipo5") }, //"Asociación de consumidores ecológicos",
            { ind: 6, tex: t("MisMapas.Tipo6") }, //"Mercado especial",
            { ind: 7, tex: t("MisMapas.Tipo7") }, //"Entrega a domicilio en todo el municipio",
            { ind: 8, tex: t("MisMapas.Tipo8") }, //"Supermercado"
            { ind: 9, tex: t("MisMapas.Tipo9") }, //"Tienda"
            { ind: 10, tex: t("MisMapas.Tipo10") }, //"Recogida en consigna"
            { ind: 11, tex: t("MisMapas.Tipo11") } //"Establecimiento hostelero con tienda"
        ]
        const tPuntos = tp.sort((a, b) => {
            if (a.tex < b.tex) return -1
            if (a.tex > b.tex) return 1
            return 0
        })
        // El componente sigue activo. //
        if (isMounted) {
            setEstado(estado => ({
                ...estado,
                idioma: valuesGlobales.idioma,
                tiposDePuntos: tPuntos
            }))
        }

        // Funcion de Limpieza para evitar el aviso:                              //
        // Warning: Can't perform a React state update on an unmounted component. //
        return () => {
            isMounted = false
        }

    }, [valuesGlobales.idioma, t])


    useEffect(() => {
        // Para limpieza. //
        let isMounted = true
        // Función que carga los puntos de los proveedores. //
        const loadPuntos = async () => {
            // proveedor: No puede estar vacío.                            //
            // podemos poner cualquier dato porque no se filtra en la API. //
            await listaPuntos().then((objDatos) => {
                //console.log("Data1,2,3: ", objDatos)
                if (objDatos?.error) {
                    console.log(objDatos.error)
                }
                // Data 1.//
                let data = objDatos?.data
                if (data?.length > 0) {

                    // Data3: Familias+Subfamillias. //
                    let fam = objDatos.data3
                    // Ajustar Familias. Añadir una Linea para la Familia que se subdivide. //
                    let familias = []
                    let codFam
                    fam.forEach(f => {
                        if (f.SubCod !== null && f.FamCod !== codFam) {
                            // Registrar Cabecera. //
                            let cab = { ...f }
                            // Importante para no dejar marcar el check. //
                            cab.SubCod = '---'
                            // Eliminar Propiedades del Objeto. //
                            //delete cab.SubDesC
                            //delete cab.SubDesE
                            //delete cab.SubFam
                            //delete cab.SubVis
                            cab.SubDesC = ''
                            cab.SubDesE = ''
                            cab.SubFam = ''
                            cab.SubVis = 1

                            familias.push({ ...cab })
                            codFam = f.FamCod
                        }
                        // Cambiar datos SubFamilias nulos por '' (vacios)  //
                        if (f.SubCod === null) {
                            f.SubCod = ''
                            f.SubDesC = ''
                            f.SubDesE = ''
                            f.SubVis = 1
                        }
                        // Registrar Familia, Subfamilia. //
                        familias.push(f)
                    })
                    // Ordenar las Familias dependiendo del idioma. //
                    familias.sort((a, b) => {
                        let x, y
                        if (valuesGlobales.idioma === "Es") {
                            // Descripciones Cashtellano
                            x = (a.FamDesC + a.SubDesC).toUpperCase()
                            y = (b.FamDesC + b.SubDesC).toUpperCase()
                        } else {
                            // Descripciiones Euskera
                            x = (a.FamDesE + a.SubDesE).toUpperCase()
                            y = (b.FamDesE + b.SubDesE).toUpperCase()
                        }
                        if (x < y) return -1
                        if (x > y) return 1
                        return 0
                    })

                    let tmpCodPunto = ""
                    let resultado = []
                    //
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].PunNum !== tmpCodPunto) {
                            tmpCodPunto = data[i].PunNum
                            resultado.push({
                                numero: data[i].PunNum,
                                titulo: data[i].PunNom,
                                productor: data[i].PunPro,
                                tipo: data[i].PunTip,
                                nota: data[i].PunNot,
                                posicion: { lat: data[i].PunLat, lng: data[i].PunLon },
                                poblacion: data[i].PunPob,
                                visible: data[i].PunVer,
                                mercados: [],
                                familias: [],
                                productoresPunto: [],
                                open: false
                            })
                        }

                        // Si hay familia. //
                        if (data[i].PrPFam !== null) {
                            let familia = data[i].PrPFam
                            // Ajustar la subFamilia. //
                            let subFami = data[i].PrPSub // === '' ? 'null' : data[i].PrPSub
                            // diferentes Familias del Punto. //
                            let pf = resultado[resultado.length - 1].familias
                            // Si la familia+subfamilia no existe en el punto. //
                            if (pf.findIndex(fam => fam.codFamilia + fam.codSubFam === familia + subFami) === -1) {
                                // Buscar las Descripciones de la SubFamilia
                                let fys = familias.find((ele) => ele.FamCod + ele.SubCod === familia + subFami)
                                if (fys !== undefined) {
                                    //resultado[resultado.length - 1].familias.push({
                                    pf.push({
                                        codFamilia: familia, //data[i].PrPFam,
                                        codSubFam: subFami,
                                        familia: valuesGlobales.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                                        famEs: data[i].FamDesC + (subFami === '' ? '' : ' - ' + fys.SubDesC),
                                        famEu: data[i].FamDesE + (subFami === '' ? '' : ' - ' + fys.SubDesE),
                                    })
                                }
                            }
                        }

                        // Si hay ProductorFamilia. //
                        if (data[i].PrPPro !== null) {
                            let productorFamilia = data[i].PrPPro
                            // Diferentes Productores del punto. //
                            let pp = resultado[resultado.length - 1].productoresPunto
                            if (pp.findIndex(pro => pro === productorFamilia) === -1) {
                                // Añadir al Array de ProductoresPunto. //
                                pp.push(productorFamilia)
                            }
                        }

                        // Mercados
                        let mer = objDatos.data2.filter(m => {
                            let res = m.PuMNum === objDatos.data[i].PunNum
                            return res
                        })
                        resultado[resultado.length - 1].mercados = mer
                    }

                    // Data2: Mercados de Puntos. //
                    let puntosMercados = objDatos.data2

                    // Calcular el punto central de puntos a ver
                    let posC = calculaCentroDePuntos(resultado)
                    //
                    // El componente sigue activo. //
                    if (isMounted) {
                        setEstado(estado => ({
                            ...estado,
                            todosPuntos: resultado,
                            familias: familias,
                            puntosMercados: puntosMercados,
                            posCentral: posC
                        }))
                    }
                }
            })
        }

        // Cargar los puntos de los proveedores. //
        loadPuntos()


        // Funcion de Limpieza para evitar el aviso:                              //
        // Warning: Can't perform a React state update on an unmounted component. //
        return () => {
            isMounted = false
        }

    }, [valuesGlobales.idioma])


    // Este efecto no haría falta si cargase bien el mapa.
    // Ahora no carga porque hay demasiados puntos y esta
    // versión no lo hace bien. Cargar despues de 1 seg.
    useEffect(() => {
        if (estado.inicio) {
            // Funcion que cambia es estado a sareko. //
            const estadoSareko = () => {
                // Cambiar el estado a sareko. //
                setEstado(estado => ({
                    ...estado,
                    inicio: false,
                    sareko: false
                }))
            }
            // Llamar a la función después de 1 segundo. //
            setTimeout(estadoSareko, 1000)
        }
    }, [estado.inicio])



    function calculaCentroDePuntos(puntos) {
        let centro = { lat: 0, lng: 0 }
        let maxLat = 0
        let minLat = 90
        let maxLon = 0
        let minLon = -90
        if (puntos !== undefined) {
            for (let i = 0; i < puntos.length; i++) {
                let p = puntos[i].posicion
                // Latitud
                if (maxLat < p.lat) {
                    maxLat = p.lat
                }
                if (minLat > p.lat) {
                    minLat = p.lat
                }
                // Longitud negativas
                if (maxLon > p.lng) {
                    maxLon = p.lng
                }
                if (minLon < p.lng) {
                    minLon = p.lng
                }
            }
        }
        centro.lat = (maxLat + minLat) / 2
        centro.lng = (maxLon + minLon) / 2
        return centro
    }



    // Cuando cambia filtro: estado.nombrePunto o estado.tipoPunto. //
    const handleChange = (name) => event => {
        // Prevents React from resetting its properties:
        event.persist();
        // Si hay un punto abierto, cerrarlo. //
        if (estado.numPuntoAbierto !== -1) {
            estado.puntosAVer[estado.numPuntoAbierto].open = false
            estado.numPuntoAbierto = -1
        }
        let valor = event.target.value
        if (valor !== null) {
            if (name === 'importancia') {
                // Convertir en numero. //
                valor = Number(valor)
            }
            setEstado(estado => ({ ...estado, [name]: valor }));
        }
    }

    // Cuando cambia filtro familias. //
    const handleChangeFamilia = (event) => {
        // Prevents React from resetting its properties:
        event.persist();
        // Si hay un punto abierto, cerrarlo. //
        if (estado.numPuntoAbierto !== -1) {
            estado.puntosAVer[estado.numPuntoAbierto].open = false
            estado.numPuntoAbierto = -1
        }
        let arrFam = event.target.value;
        setEstado(estado => ({ ...estado, famYSub: arrFam }));
    }


    // Cuando cambia filtro Tipo de Punto. //
    const handleTipoDePunto = (event) => {
        // Prevents React from resetting its properties:
        event.persist();
        // Si hay un punto abierto, cerrarlo. //
        if (estado.numPuntoAbierto !== -1) {
            estado.puntosAVer[estado.numPuntoAbierto].open = false
            estado.numPuntoAbierto = -1
        }
        let arrTipos = event.target.value;
        setEstado(estado => ({ ...estado, tiposSelec: arrTipos }));
    }


    // Cuando cambia filtro Sareko. //
    const handleChangeSareko = (event) => {
        // Prevents React from resetting its properties:
        event.persist();
        // Si hay un punto abierto, cerrarlo. //
        if (estado.numPuntoAbierto !== -1) {
            estado.puntosAVer[estado.numPuntoAbierto].open = false
            estado.numPuntoAbierto = -1
        }
        //let sar =  event.target.checked)        
        let sar = !estado.sareko
        setEstado(estado => ({ ...estado, sareko: sar }));
    }


    // Filtrar los Puntos según el Filtro. //
    useEffect(() => {
        // Para limpieza. //
        let isMounted = true
        // Vaciar puntos actuales. //
        setEstado(estado => ({ ...estado, puntosAVer: [], numPuntoAbierto: -1 }))
        let resultado = estado.todosPuntos.filter(p => {
            let ret = true
            if (estado.nombrePunto !== '') {
                ret = p.titulo.toUpperCase().includes(estado.nombrePunto.toUpperCase())
            }
            /*
            if (ret && estado.tipoPunto !== -1) {
                ret = p.tipo === estado.tipoPunto
            }
            */
            if (ret && estado.tiposSelec.length > 0) {
                ret = (estado.tiposSelec.indexOf(p.tipo) > -1)
            }

            // si ret viene cierto mirar filtro familias. //
            if (ret && estado.famYSub.length > 0) {
                if (p.familias.length > 0) {
                    let nroFamSub = 0
                    estado.famYSub.forEach(fys => {
                        // Si encuentra la Familia+Subfamilia. //
                        if (p.familias.findIndex(f => f.codFamilia + f.codSubFam === fys) > -1) {
                            // Incrementar contador. //
                            nroFamSub++
                        }
                    })
                    // Si TODAS las familias/subFamilias marcadas están en el punto. //
                    ret = (nroFamSub === estado.famYSub.length)
                } else {
                    ret = false
                }
            }

            // si ret viene cierto mirar importancia del punto
            if (ret && estado.importancia !== -1) {
                if (estado.importancia === 0) {
                    // 3 O Más mercados/productores
                    if (p.mercados.length >= 3 || p.productoresPunto.length >= 3) {
                        ret = true
                    } else {
                        ret = false
                    }
                } else {
                    if (p.mercados.length <= 3 && p.productoresPunto.length <= 3) {
                        ret = true
                    } else {
                        ret = false
                    }
                }
            }

            // Si ret viene cierto mirar el tipo de sareko
            if (ret) {
                if (estado.sareko) {
                    ret = p.mercados.length > 0 ? true : false
                } else {
                    ret = true
                }
            }
            return ret
        })

        // El componente sigue activo. //
        if (isMounted) {
            setEstado(estado => ({ ...estado, puntosAVer: resultado }))
        }

        // Funcion de Limpieza para evitar el aviso:                              //
        // Warning: Can't perform a React state update on an unmounted component. //
        return () => {
            isMounted = false
        }


    }, [
        estado.todosPuntos, estado.nombrePunto,
        estado.sareko, estado.tiposSelec,
        estado.famYSub, estado.familias,
        estado.importancia, estado.mercados
    ])



    const puntoExistenteOpen = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        // Si hay un punto abierto, cerrarlo. //
        if (estado.numPuntoAbierto !== -1) {
            pAV[estado.numPuntoAbierto].open = false
        }
        pAV[i].open = true
        // Guardar el puntero del Punto Abierto. //
        setEstado({ ...estado, puntosAVer: pAV, numPuntoAbierto: i })
    }

    const puntoExistenteClose = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        pAV[i].open = false
        setEstado({ ...estado, puntosAVer: pAV, numPuntoAbierto: -1 })
    }


    const registraMercados = (p) => event => {
        valuesGlobales.mercadosMapa = p.mercados
        valuesGlobales.punto = p.titulo
        return true
    }



    const ampliacionPunto = (p, n) => (
        <Fragment key={"P_" + n}>
            <Grid container style={{
                display: "flex",
                alignItems: 'center', // Centra Verticalmente
                //border: "2px solid red",
            }}
            >
                <Grid item xs={6}>
                    <Typography className={classes.azul17} /*display="inline"*/>
                        {p.titulo.trim()}
                    </Typography>
                </Grid>
                <Grid item xs={6}
                    // Para que alinee el botón a la derecha. //
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    {p.mercados.length !== 0 &&
                        <BotonValidacion
                            texto={t("MisMapas.HacerPedidoEnSareko")}
                            salto='/MisMercados'
                            validacion={registraMercados(p)}
                            style={{ minWidth: 210 }}
                        />
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.azul15}>
                        {p.poblacion}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.gris15}>
                        {/*tiposDePuntos[p.tipo]*/}
                        {textoTipoPunto(p.tipo)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.gris11}>
                        {p.nota}
                    </Typography>
                </Grid>
            </Grid>
            {
                p.familias.length !== 0 &&
                <>
                    <Divider />
                    <br />
                    <Grid container>
                        <Grid item xs={12}>
                            {(p.familias.length !== 0) &&
                                <Typography className={classes.azul15}>
                                    {t("MisMapas.TiposProducto") + ":"}
                                </Typography>
                            }
                        </Grid>
                        {p.familias.map((f, i) => (
                            <Grid item key={"famP" + i} xs={12} /*sx={{ mb: 1.5, mt: i === 0 ? 1 : -1 }}*/>
                                <Typography /*noWrap={false} sx={{ ml: 2, mr: 1 }}*/ className={classes.verde10}>
                                    {f.codFamilia === null ? "" : (valuesGlobales.idioma === "Es" ? f.famEs : f.famEu)}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </>
            }
        </Fragment >
    )



    function colorDelPunto(p) {
        let color
        color = "#ff6333"
        return color
    }

    function colorDelGlyph(p) {
        let color
        color = "white"
        return color
    }

    function colorDelBorde(p) {
        let color
        color = "orange"
        return color
    }

    /*
    ///   SE HA QUITADO ESTE BOTON     
    function DondeIr() {
        let ret = ''
        // Si el usuario esta sin identificar ir Indetificarse
        if (valuesGlobales.emailUsuario === "") {
            // Poner que vuelva a Mis Mercados
            valuesGlobales.paginaOrigen = "/"
            ret = "/Identificarse"
        } else {
            // Si el usuario esta identificado ir a Mis mercados
            ret = "/MisMercados"
        }
        return ret
    }
    */


    const listaMapa = (
        <Grid container>
            <APIProvider apiKey={config.passMapas}>
                <Map
                    // Importante para que refresque al cambiar coordenadas. //
                    key={estado.posCentral.lat}
                    /// Importante poner ancho y alto (Sin ellos No se visualiza) //
                    style={{ width: '100vw', height: '65vh' }}
                    //////////////onClick={nuevoPunto}
                    //zoom={10} // No deja ampliar el mapa
                    defaultZoom={9}
                    //center={posEneek} // No deja moverse
                    defaultCenter={estado.posCentral}
                    mapId={'MiMapa'}
                >
                    {estado.puntosAVer.map((p, i) => (
                        <Fragment key={"k1_" + i}>
                            <AdvancedMarker
                                key={"k2_" + i}
                                position={p.posicion}
                                draggable={false}
                                onClick={puntoExistenteOpen(i)}
                            //onDragEnd={soltamosPunto(i)}
                            >
                                <Pin
                                    background={colorDelPunto(p)}
                                    borderColor={colorDelBorde(p)}
                                    glyphColor={colorDelGlyph(p)}
                                >
                                </Pin>
                            </AdvancedMarker>
                            {p.open && <InfoWindow
                                key={"k3_" + i}
                                position={p.posicion}
                                minWidth={400}
                                onCloseClick={puntoExistenteClose(i)}
                            >
                                {ampliacionPunto(p, i)}
                            </InfoWindow>}
                        </Fragment>
                    ))}
                </Map>

            </APIProvider>
        </Grid>
    )

    // Para que al seleccionar las familias, se pinte su nombre en el selector. //
    const verTextos = () => {
        let ret = ''
        estado.famYSub.forEach(cod => {
            let i = estado.familias.findIndex(f => { return f.FamCod + f.SubCod === cod })
            if (estado.familias[i].SubCod === '') {
                ret += (valuesGlobales.idioma === "Es" ? estado.familias[i].FamDesC : estado.familias[i].FamDesE) + ', '
            } else {
                ret += (valuesGlobales.idioma === "Es" ? estado.familias[i].SubDesC : estado.familias[i].SubDesE) + ', '
            }
        })
        return ret
    }
    // Para que al seleccionar las familias, se pinte su nombre en el selector. //
    const verTipoPuntos = () => {
        let ret = ''
        estado.tiposSelec.forEach(cod => {
            let i = estado.tiposDePuntos.findIndex(p => { return p.ind === cod })
            ret += estado.tiposDePuntos[i].tex + ', '
        })
        return ret
    }



    const cabeceraMapa = (
        <Fragment>
            <Grid container className={classes.cabFiltros}>
                <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 20 }} display="inline">
                    {t("MisMapas.FiltrarPor")}
                </Typography>
                <Grid item className={classes.cabFiltros1} >
                    <Box width={160}>
                        <TextField
                            type="text"
                            id="nombrePunto"
                            //required   // comentado para que quite el asterisco "Nombre Punto *"
                            label={t("MisMapas.NombrePunto")}
                            value={estado.nombrePunto}
                            className={classes.textFiltro}
                            fullWidth
                            onChange={handleChange('nombrePunto')}
                        />
                    </Box>
                </Grid>
                <Grid item className={classes.cabFiltros1} style={{ marginLeft: 7, marginTop: -14 }}>
                    {/*
                // Esto es por si quieren validar con botones. //
                <Button onClick={handleClickOpen}>Alimentos</Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Seleccione las Familias</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                */}
                    <FormControl style={{ width: 320, maxWidth: 320 }}>
                        <InputLabel htmlFor={"familias"} /* corregir warning de Label */>
                            {t("MisMapas.Alimentos")}
                        </InputLabel>
                        <Select
                            //className={classes.selectFiltro}
                            inputProps={{ id: "familias", name: "familias" } /* corregirr warning de Label */}
                            //id="familias"
                            multiple
                            value={estado.famYSub}
                            onChange={handleChangeFamilia}
                            style={{ fontSize: 11 }}

                            //input={<Input />}
                            renderValue={verTextos}
                            MenuProps={MenuProps}
                        >
                            {estado.familias.map((f, i) =>
                                // Si es la Cabecera de una Familia que se subdivide. //
                                (f.SubCod === '---') ?
                                    (
                                        <MenuItem
                                            key={f.FamCod + f.SubCod}
                                            value={f.FamCod + f.SubCod}
                                            style={{ height: ITEM_HEIGHT, marginLeft: 10 }} // Altura de cada elemento
                                        >
                                            <ListItemText
                                                primaryTypographyProps={{ style: { fontSize: 11 } }}
                                                primary={(valuesGlobales.idioma === "Es" ? f.FamDesC : f.FamDesE)}
                                            />
                                        </MenuItem>
                                    ) :
                                    (
                                        <MenuItem
                                            key={f.FamCod + f.SubCod}
                                            value={f.FamCod + f.SubCod}
                                            style={{ height: ITEM_HEIGHT }} // Altura de cada elemento
                                        >
                                            <Checkbox
                                                name={f.FamCod + f.SubCod + i}
                                                checked={estado.famYSub.indexOf(f.FamCod + f.SubCod) > -1}
                                                size='small'
                                                style={{ marginLeft: (f.SubCod === "") ? 0 : 10 }}
                                            />
                                            <ListItemText
                                                primaryTypographyProps={{ style: { fontSize: 11 } }}
                                                primary={
                                                    (f.SubCod === "")
                                                        ? (valuesGlobales.idioma === "Es" ? f.FamDesC : f.FamDesE)
                                                        : (valuesGlobales.idioma === "Es" ? f.SubDesC : f.SubDesE)
                                                } />
                                        </MenuItem>
                                    )
                            )}
                        </Select>
                    </FormControl>
                    {/*
                   // Aqui habría que poner los filtros correctos al darle aceptar o quitar si cancela. //
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                */}



                </Grid>

                <Grid item className={classes.cabFiltros1} style={{ marginLeft: 7, marginTop: -14 }}>
                    <FormControl style={{ width: 320, maxWidth: 320 }}>
                        <InputLabel htmlFor={"tipos"} /* corregir warning de Label */>
                            {t("MisMapas.Establecimiento")}
                        </InputLabel>
                        <Select
                            //className={classes.selectFiltro}
                            inputProps={{ id: "tipos", name: "tipos" } /* corregir warning de Label */}
                            //id="tipos"
                            //name="tipos"
                            multiple
                            value={estado.tiposSelec}
                            onChange={handleTipoDePunto}
                            style={{ fontSize: 12 }}

                            //input={<Input />}
                            renderValue={verTipoPuntos}
                            MenuProps={MenuProps}
                        >
                            {estado.tiposDePuntos.map((p, i) =>
                                <MenuItem
                                    key={p.ind}
                                    value={p.ind}
                                    style={{ height: ITEM_HEIGHT }} // Altura de cada elemento
                                >
                                    <Checkbox
                                        name={"tipo" + i}
                                        checked={estado.tiposSelec.indexOf(p.ind) > -1}
                                        size='small'
                                    />
                                    <ListItemText
                                        primaryTypographyProps={{ style: { fontSize: 13 } }}
                                        primary={p.tex}
                                    />
                                </MenuItem>

                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ marginLeft: 5 }}>
                    <Box width={180}>
                        <Typography style={{ color: '#616161', fontSize: 16, fontWeight: 400, marginLeft: 5 }} display="inline">
                            {t("MisMapas.PuntosSareko")}
                            <Checkbox
                                labelid="punto-sareko"
                                id="punto-sareko"
                                checked={estado.sareko}
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                onChange={handleChangeSareko}
                            />
                        </Typography>
                    </Box>
                </Grid>

                {/*
                <Grid item style={{ marginLeft: 10 }}>
                    <Box width={140}>
                        <NativeSelect
                            value={estado.bajoPedido}
                            onChange={handleChange('bajoPedido')}
                            name="bajoPedido"
                            className={classes.textFiltro}
                        >
                            <option value="-1" key={"bajo-1"}>{t("MisMapas.Todos")}</option>
                            {tiposDeBajoPedido.map((p, i) => {
                                return (<option value={i} key={"bajo" + i}>{p}</option>)
                            })}
                        </NativeSelect>
                        {(() => {
                            return (
                                <Typography className={classes.mensajeHelper}>
                                    {t("MisMapas.PuntosSareko")}
                                </Typography>
                            )
                        })()}
                    </Box>
                </Grid>
                */}
            </Grid>

        </Fragment >
    )




    const pieMapa = (
        <Fragment>
            <Grid container className={classes.pieMapa}>
                <Typography style={{ color: "#026747", fontSize: 30, fontWeight: 600, marginRight: 20 }} display="inline">
                    {t("MisMapas.PuntosSarekoPie")}
                </Typography>
                {/*   --- QUITAR ESTE BOTON -----
                <Boton
                    texto={t("MisMapas.HacerPedidoEnSareko")}
                    //salto={valuesGlobales.nombreUsuario === '' ? '/Identificarse' : '/MisMercados'}
                    salto={DondeIr()}

                    //validacion={filtraMercadosDePunto}
                    desactivado={false}                 // En funcion de mercados posibles
                >
                </Boton>
                */}
            </Grid>
            <Divider style={{ marginTop: 8 }} />
            <Typography style={{ color: ThemeGeroa.palette.gris.main, fontSize: 16, fontWeight: 400 }} display="inline">
                {t("MisMapas.Texto")}
            </Typography>

        </Fragment >
    )

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Fragment>
                <Container maxWidth="lg" className={classes.registro}>
                    {cabeceraMapa}
                    {listaMapa}
                    {pieMapa}
                </Container>
                <br />
            </Fragment>
        </MuiThemeProvider>
    );
}

export default withTranslation()(MisMapas)