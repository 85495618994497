import React, { Fragment, Suspense, useEffect } from "react";
import { HashRouter as BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';
import IndexRoutes from "./routes/rutas.js";

import { createStore } from 'react-hookstore';

// i18n es necesario para la aplicación
// La siguiente linea es para que pase el error al compilar
// eslint-disable-next-line
import i18n from './i18n';
import Cabecera from './core/cabecera.js'
import Header from './core/header.js'
import Footer from './core/footer.js'
import ThemeGeroa from './components/ThemeGeroa.js'
import { JsonAArray } from './config/funcionesPropias.js'
import auth from './apis/auth-helper.js'

const App = () => {

   // leer el Idioma último usado
   let idio = localStorage.getItem('idioma')
   idio = idio === null ? 'Eus' : idio

   // Borrar carrito y mercado actual
   localStorage.removeItem('carrito')
   localStorage.removeItem('mercadoActual')
   //

   // Lee el carrito
   let itemsCarrito = localStorage.getItem('carrito')
   itemsCarrito = itemsCarrito === null ? {} : JSON.parse(itemsCarrito)


   // Lee el mercado Actual
   let mercadoActual = localStorage.getItem('mercadoActual')
   mercadoActual = mercadoActual === null ? '' : mercadoActual

   // Lee los mercados. Para que refresque en productos
   let mercados = localStorage.getItem('mercados')
   mercados = mercados === null ? [] : JSON.parse(mercados)

   // Lee el token
   let jwt = auth.isAuthenticated()


   let oRedsys = document.referrer !== undefined &&
      (document.referrer.substr(0, 20) === "https://sis.redsys.e" ||
         document.referrer.substr(0, 20) === "https://sis-t.redsys")

   // iva Usuario: 0 Sin Iva, 1 Con Iva, 2 Con Recargo. //
   let iva = 1
   if (jwt) {
      if (jwt.user.ivaUsuario) {
         iva = jwt.user.recargo ? 2 : 1
      } else {
         iva = 0
      }
   }

   // Crear Almacen de variables globales
   createStore('DatosGlobales',
      {
         idioma: idio,
         nombreUsuario: jwt ? jwt.user.name : '',
         emailUsuario: jwt ? jwt.user.email : '',
         nifUsuario: jwt ? jwt.user.nif : '',
         mercadosUsuario: jwt ? jwt.user.tarifas : [],
         mercadoActual: mercadoActual,
         intentoMercado: mercadoActual !== '' ? mercadoActual : '',
         paginaOrigen: '/',
         ivaUsuario: iva,
         dirUsuario: [],
         opcionMisProductos: "1",                               // Novedades
         numeroCarrito: JsonAArray(itemsCarrito).length,
         carrito: itemsCarrito,
         mercados: mercados, // [],
         productos: [],
         unidades: ["Ud", "Kg", "Lit"],
         claveCambioPassword: '',
         usuarioCambioPassword: '',
         poblaciones: [],
         origenRedsys: oRedsys,
         pedidosAGuardar: [],
         mercadosMapa: [],
         usuarioAltaMercado: '',
         punto: '',
         notificaciones: "",
         todos: [{ id: 0, text: 'pppppp' }]
      }
   )

   // Funcion para borrar cache. //
   const clearCacheData = () => {
      caches.keys().then((names) => {
         names.forEach((name) => {
            caches.delete(name)
         })
      })
      console.log('Cache borrada')
   }

   useEffect(() => clearCacheData(), [])

   return (
      <Fragment>
         <Suspense fallback={null}>
            <MuiThemeProvider theme={ThemeGeroa}>
               <BrowserRouter>
                  <Cabecera />
                  <Header />
                  <IndexRoutes />
                  <Footer />
               </BrowserRouter>
            </MuiThemeProvider>
         </Suspense>
      </Fragment>
   )
}

export default App